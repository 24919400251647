import axios from 'axios';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LngLatLike, useMap } from 'react-map-gl';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';
import { MAP_SEARCH_STEPS } from '../../constants/joyride';
import useJoyride from '../../hooks/useJoyride';
import { isValidPointCoordinate } from '../../utils/biomass';
import Icon from '../Common/Icon';
import { SearchInput, SearchInputWrapper, SearchItem, SearchResultsWrapper, Wrapper } from './style';
import { MapboxSearchFeatureProperties } from './types';

const MapSearch = () => {
  const searchRef = useRef<HTMLDivElement>(null);
  const { mapRoot } = useMap();
  const [results, setResults] = useState<GeoJSON.FeatureCollection<
    GeoJSON.Point,
    MapboxSearchFeatureProperties
  > | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [point, setPoint] = useState<GeoJSON.Point | null>(null);
  const debouncedQuery = useDebounce<string>(searchTerm, 500);
  useOnClickOutside(searchRef, () => {
    setSearchTerm('');
  });

  const { t } = useTranslation();

  const handleSetPoint = useCallback(
    (value: string) => {
      const [latitude, longitude] = value.split(',').map(Number);
      const pointToFly: GeoJSON.Point = {
        type: 'Point',
        coordinates: [longitude, latitude]
      };
      if (JSON.stringify(point) !== JSON.stringify(pointToFly)) {
        setPoint(pointToFly);
      }
    },
    [point]
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      if (isValidPointCoordinate(value)) {
        handleSetPoint(value);
      }
      setSearchTerm(value);
    },
    [handleSetPoint]
  );

  const fetchData = useCallback(async (query: string) => {
    const fetchResults = await axios.get(
      `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&access_token=${
        process.env.REACT_APP_MAPBOX_TOKEN as string
      }`
    );

    if (fetchResults) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setResults(fetchResults.data);
    }
  }, []);

  const handleOnAddressClick = useCallback(
    (index: number) => {
      const itemToFlyTo = results?.features[index];
      const FIT_BOUNDS_PROPS = {
        duration: 1000,
        zoom: 11
      };

      if (itemToFlyTo?.properties.bbox) {
        const { bbox } = itemToFlyTo.properties;
        mapRoot?.fitBounds([bbox[0], bbox[1], bbox[2], bbox[3]], FIT_BOUNDS_PROPS);
      } else if (itemToFlyTo?.geometry) {
        mapRoot?.fitBounds(
          [
            [itemToFlyTo.geometry.coordinates[0] - 0.1, itemToFlyTo.geometry.coordinates[1] - 0.1],
            [itemToFlyTo.geometry.coordinates[0] + 0.1, itemToFlyTo.geometry.coordinates[1] + 0.1]
          ],
          FIT_BOUNDS_PROPS
        );
      }

      setTimeout(() => {
        setSearchTerm('');
      }, 300);
    },
    [mapRoot, results?.features]
  );

  const handleEnterPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && point) {
        mapRoot?.flyTo({
          center: point.coordinates as LngLatLike,
          duration: 1000,
          zoom: 11
        });
        setSearchTerm('');
        setResults(null);
        setPoint(null);
        window.removeEventListener('keydown', handleEnterPress);
      }
    },
    [mapRoot, point]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEnterPress);
    // cleanup function to remove on unmount
    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [handleEnterPress]);

  useEffect(() => {
    // Check for coordinates first
    if (debouncedQuery !== '') {
      if (!isValidPointCoordinate(debouncedQuery)) {
        // Not a point coordinate, fetch results from Mapbox
        void fetchData(debouncedQuery);
      }
    } else {
      setResults(null);
    }
  }, [debouncedQuery, fetchData, handleSetPoint]);

  useJoyride(MAP_SEARCH_STEPS(t));

  return (
    <Wrapper ref={searchRef} data-test-id="map-search-bar">
      <SearchInputWrapper>
        <Icon color={'grey'} variant={'SEARCH'} size={14} />
        <SearchInput placeholder={String(t('Lat,long or location'))} onChange={handleOnChange} value={searchTerm} />
      </SearchInputWrapper>
      <SearchResultsWrapper open={results ? results.features.length > 0 : false}>
        {results?.features.map((feature, index) => (
          <SearchItem key={feature.properties.mapbox_id} onClick={() => handleOnAddressClick(index)}>
            {`${feature.properties.name} , ${feature.properties.context.country.name}`}
          </SearchItem>
        ))}
      </SearchResultsWrapper>
    </Wrapper>
  );
};

export default MapSearch;
